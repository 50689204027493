<template>
    <img :id="id" v-if="src !== null" :src="src" @click="$emit('click')" @load="$emit('load')">
</template>

<script>
import axios from 'axios';
import authHeader from '../services/auth-header';
export default {
    name: 'AssetImage',
    props: {
        filename: String,
        client_id: String,
        claim_id: String,
        id: String,
        endpoint: {
            type: String,
            required: false,
        }
    },
    data(){
        return {
            src: null,
        }
    },
    mounted() {

        return axios.get(`https://api.varsanpr.com/api/claims/${this.claim_id}/evidence/${this.filename}?client_id=${this.client_id}`, {
            responseType: 'arraybuffer',
            headers: authHeader()
        })
        .then(response => {
            this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
        })
        .catch(error => {
            this.$error(`Failed to download image ${this.filename}`, error);
            return null;
        });

    },
    methods: {
          loadImage(){
            return axios.get(`https://api.varsanpr.com/api/claims/${this.claim_id}/evidence/${this.filename}?client_id=${this.client_id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
            })
            .catch(error => {
                this.$error(`Failed to download image ${this.filename}`, error);
                return null;
            });
          }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        client_id: function(val){
            this.loadImage();
        },
        claim_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>

img {
    width: 100%;
    /* width: auto; */
}

</style>