<template>
  <slot></slot>
  <!-- <div class="row">
    <div class="col">
      <div class="card invoice" style="margin-top: 0px !important;">
        <div class="card-body">
          <div class="invoice-header">
          <div class="row">
              <div class="col-9">
                  <h3>Outstanding Losses</h3>
              </div>
              <div class="col-3">
                  <span class="invoice-issue-date">Date: <timestamp :time="claim.date_received" format="dd/MM/yyyy"/></span>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Fuel Type</th>
                  <th scope="col">Litres</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{claim.fuel}}</th>
                  <td>{{claim.litres}}</td>
                  <td>£{{claim.value}}</td>
                </tr>
                <tr v-if="claim.fuel_second !== null">
                  <th scope="row">{{claim.fuel_second}}</th>
                  <td>{{claim.litres_second}}</td>
                  <td>£{{claim.value_second}}</td>
                </tr>
                <tr>
                  <th scope="row">Administration Fee</th>
                  <td></td>
                  <td>£{{currentFee.toFixed(2)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
          
        </div>
        <div class="card-footer">
          <div class="row invoice-summary">
            <div class="col-lg-4">
                <div class="invoice-info">
                    <p>Claim Reference: <span>{{claim.reference}}</span></p>
                    <p>Issue Date: <span><timestamp :time="claim.date_received" format="dd/MM/yyyy"/></span></p>
                    <p>Due Date: <span v-if="claim.dates.date_second_driver || claim.dates.date_posted_driver" :class="{'text-danger': overDue, 'text-warning': nearlyDue }"><timestamp :time="dueDate" format="dd/MM/yyyy"/></span> <span class="text-success" v-else>TBC</span></p>
                    <div class="invoice-info-actions">
                        <a href="#" class="btn btn-info m-r-xs" type="button">Print Invoice</a>
                        <a href="#" class="btn btn-success m-l-xs" type="button">Download</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5"></div>
            <div class="col-lg-3">
                <div class="invoice-info">
                    <p>Subtotal <span>£{{amountToCollect.toFixed(2)}}</span></p>
                    <p>Payments Made <span :class="{'text-success': paymentsMade > 0}">- £{{paymentsMade.toFixed(2)}}</span></p>
                    <p class="bold">Total <span :class="{'text-danger': withDeductions > 0}">£{{withDeductions.toFixed(2)}}</span></p>
                    <div class="invoice-info-actions">
                        <a href="#" class="btn btn-primary" type="button">Sign Invoice</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div> -->

  <h2 class="sub-heading"> {{$t('claims.processing.outstanding_losses')}}</h2>
  <div class="row">
      <div class="col">
          <div class="card invoice col-11" style="margin: auto;">
              <div class="card-body">
                  <div class="invoice-header">
                      <div class="row">
                          <div class="col-9">
                              <p style="color: #fff;"> FINANCIAL INFORMATION </p>
                          </div>
                          <div class="col-3">
                              <span class="invoice-issue-date"> <timestamp :time="claim.date_received" format="dd/MM/yyyy"></timestamp> </span>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="table-responsive">
                          <div class="table invoice-table">
                              <thead>
                                  <tr>
                                      <th scope="col"></th>
                                      <th scope="col" class="desc">{{$t('labels.description')}}</th>
                                      <th scope="col"></th>
                                      <th scope="col" class="qty">{{$t('labels.quantity')}}</th>
                                      <th scope="col">{{$t('labels.amount')}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td scope="row">1.</td>
                                      <td> {{claim.fuel}} (Pump {{claim.pump}})</td>
                                      <td></td>
                                      <td> {{claim.litres}} Liters </td>
                                      <td> £{{claim.value}}</td>
                                  </tr>
                                  <tr v-if="claim.fuel_second !== null && !savingFuel">
                                      <td scope="row">2.</td>
                                      <td> {{claim.fuel_second}} (Pump {{claim.pump_second}})</td>
                                      <td></td>
                                      <td> {{claim.litres_second}} Liters </td>
                                      <td> £{{claim.value_second}}</td>
                                  </tr>
                                  <tr>
                                      <td v-if="claim.fuel_second"> 3.</td>
                                      <td v-else scope="row">2.</td>
                                      <td> {{$t('labels.admin_fee')}} </td>
                                      <td></td>
                                      <td></td>
                                      <td> £{{feeMinusVat}}</td>
                                  </tr>
                                  <tr>
                                      <td v-if="claim.fuel_second"> 4.</td>
                                      <td v-else scope="row">3.</td>
                                      <td> VAT on Administration Fee </td>
                                      <td> </td>
                                      <td></td>
                                      <td> £{{vatCharge}} </td>
                                  </tr>
                              </tbody>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card-footer">
                  <div class="row invoice-summary">
                      <div class="col-lg-5">
                          <div class="invoice-info">
                              <p> {{$t('claims.processing.reference-number')}} : <span> {{claim.reference}} </span> </p>
                              <p> {{$t('claims.processing.vehicle-registration')}} : <span> {{claim.vehicle.plate}} </span> </p>
                              
                              <!-- <div class="invoice-info-actions">
                                <a href="#" class="btn btn-info m-r-xs" type="button">Print Invoice</a>
                                <a href="#" class="btn btn-success m-l-xs" type="button">Download</a>
                              </div> -->
                          </div>
                      </div>
                      <div class="col-lg-3"></div>
                      <div class="col-lg-4">
                          <div class="invoice-info">
                              <p> {{$t('labels.sub_total')}} : <span> £{{amountToCollect.toFixed(2)}}</span> </p>
                              <p> {{$t('labels.payment_made')}} : <span :class="{'text-success': paymentsMade > 0}"> - £{{paymentsMade.toFixed(2)}} </span> </p>
                              <p class="total"> {{$t('sites.invoice.total').toUpperCase()}} : <span> £{{withDeductions.toFixed(2)}} </span></p>
                              <!-- <div class="invoice-info-actions">
                                <a href="#" class="btn btn-primary" type="button">Sign Invoice</a>
                              </div> -->
                          </div>
                      </div>
                  </div>
                  <!-- TODO - Print, Download, sign Invoice features -->
              </div>
          </div>

          <!-- Edit fuel Modal -->
          <div class="modal fade" id="editFuelModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content p-h-xxl p-v-xxl">
                      <div class="modal-title flex-center">
                          <h3 class="m-r-sm"> {{$t('claims.processing.nmop.edit_fuel')}} </h3>
                      </div>
                      <form v-on:@submit.prevent="saveFuel" id="FuelForm">
                          <div class="row">
                              <div class="flex-center m-b-sm space-between">
                                  <div class="col-6">
                                      <label for="fuelType" class="form-label"> {{$t('claims.new.fuel_type')}} <span class="text-danger">*</span> </label>
                                      <select class="form-select" id="fuelType" v-model="claim.fuel" required>
                                          <option value="" selected> Select Fuel Type </option>
                                          <option v-for="type in fuelTypes" :value="type" :key="type"> {{type.toUpperCase()}}</option>
                                      </select>
                                  </div>
                                  <div class="col-6">
                                      <label for="fuelValue" class="form-label"> {{$t('claims.new.value')}} <span class="text-danger">*</span> (£) </label>
                                      <input type="number" id="fuelValue" class="form-control" v-model="claim.value" placeholder="0.00" step="0.01" min="0.00" max="10000" required>
                                  </div>
                              </div>

                              <div class="flex-center m-b-sm space-between">
                                  <div class="col-6">
                                      <label for="litres" class="form-label"> {{$t('claims.new.litres')}} <span class="text-danger">*</span> </label>
                                      <input type="number" id="litres" class="form-control" v-model="claim.litres" placeholder="0.00" step="0.01" min="0.00" max="10000" required>
                                  </div>
                                  <div class="col-6">
                                      <label for="pump" class="form-label"> {{$t('claims.new.pump')}} <span class="text-danger">*</span> </label>
                                      <input type="number" min="1" max="10" class="form-control" v-model="claim.pump" placeholder="Pump number" required>
                                  </div>
                              </div>
                          </div>

                          <div>
                              <label class="form-label"> {{$t('claims.processing.nmop.reason')}} <span class="text-danger">*</span> </label>
                              <input type="text" v-model="reason" class="form-control" placeholder="Please explain why you are changing fuel details" required>
                          </div>
                          <div class="flex-center m-t-md">
                              <button class="btn btn-secondary m-r-md" data-bs-dismiss="modal"> {{$t('global.cancel')}} </button>
                              <button class="btn btn-primary" :class="reason.length < 5 ? 'disabled' : ''" @click.prevent="saveFuel"> {{$t('global.save_changes')}}</button>
                          </div>
                      </form>    
                  </div>
              </div>
          </div>

          <!-- Add/Edit 2nd fuel Modal -->
          <div class="modal fade" id="editSecondFuelModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content p-h-xxl p-v-xxl">
                      <div class="modal-title flex-center">
                          <h3 class="m-r-sm" v-if="claim.fuel_second"> {{$t('claims.processing.nmop.edit_second_fuel')}} </h3>
                          <h3 v-else class="m-r-sm"> {{$t('claims.processing.nmop.add_second_fuel')}}</h3>
                      </div>
                      <!-- <form v-on:@submit.prevent="saveFuelSecond" ref="secondFuelForm"> -->
                          <div class="row">
                              <div class="flex-center m-b-sm space-between">
                                  <div class="col-6">
                                      <label for="fuelType" class="form-label"> {{$t('claims.new.fuel_type')}} <span class="text-danger">*</span> </label>
                                      <select class="form-select" id="fuelType" v-model="claim.fuel_second" required>
                                          <option value="null" selected> Select Fuel Type </option>
                                          <option v-for="type in fuelTypes" :value="type" :key="type"> {{type.toUpperCase()}}</option>
                                      </select>
                                  </div>
                                  <div class="col-6">
                                      <label for="fuelValue" class="form-label"> {{$t('claims.new.value')}} <span class="text-danger">*</span> (£) </label>
                                      <input type="number" id="fuelValue" class="form-control" v-model="claim.value_second" placeholder="0.00" step="0.01" min="0.00" max="10000" required>
                                  </div>
                              </div>

                              <div class="flex-center m-b-sm space-between">
                                  <div class="col-6">
                                      <label for="litres" class="form-label"> {{$t('claims.new.litres')}} <span class="text-danger">*</span> </label>
                                      <input type="number" id="litres" class="form-control" v-model="claim.litres_second" placeholder="0.00" step="0.01" min="0.00" max="10000" required>
                                  </div>
                                  <div class="col-6">
                                      <label for="pump" class="form-label"> {{$t('claims.new.pump')}} <span class="text-danger">*</span> </label>
                                      <input type="number" min="1" max="10" class="form-control" v-model="claim.pump_second" placeholder="Pump number" required>
                                  </div>
                              </div>
                          </div>

                          <div>
                              <label class="form-label"> {{$t('claims.processing.nmop.reason')}} <span class="text-danger">*</span> </label>
                              <input type="text" v-model="reason" class="form-control" placeholder="Please explain why you are changing fuel details" required>
                          </div>
                          <div class="flex-center m-t-md">
                              <button class="btn btn-secondary m-r-md" data-bs-dismiss="modal"> {{$t('global.cancel')}} </button>
                              <button class="btn btn-primary" :class="reason.length < 5 ? 'disabled' : ''" @click.prevent="saveFuelSecond"> {{$t('global.save_changes')}}</button>
                          </div>
                      <!-- </form>     -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Timestamp from '../../../../../components/Timestamp.vue';
import {DateTime} from 'luxon';
import ClaimsService from '../../../../../services/claims.service';

export default {
  name: "DriveoffDetails",
  components: {
    Timestamp
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data(){
    return {
      editFuelModal: null,
      editSecondFuelModal: null,
      fuelTypes: [
        'Petrol',
        'Diesel',
        'AdBlue',
        'Liquid Petroleum Gas (LPG)'
      ],
      reason: "",
      savingFuel: false,
      savingFuelSecond: false
    }
  },
  mounted(){
    // if(this.claim.lock_fee !== 1){
    //   let today = DateTime.local();
    //   if(this.claim.dates.date_posted_driver !== null && this.claim.lock_lower_fee != 1){
    //     if(DateTime.local().minus({'days': 17}) > DateTime.fromSeconds(this.claim.dates.date_posted_driver)){
    //       this.cost += 30;
    //       this.over = true;
    //     }
    //   }
    // }else{
    //   this.cost = parseFloat(this.claim.locked_fee);
    // }

    // this.cost = parseFloat(this.cost);
    // this.amountToCollect = this.cost + parseFloat(this.claim.value);

    // if(this.claim.fuel_second !== null){
    //   this.amountToCollect += parseFloat(this.claim.value_second);
    // }
    this.editSecondFuelModal = new bootstrap.Modal(document.getElementById('editSecondFuelModal'));
    this.editFuelModal = new bootstrap.Modal(document.getElementById('editFuelModal'));
  },
  methods: {
    saveFuel() {
      this.savingFuel = true;
      let data = {
        client_id: this.$store.state.auth.user.selectedClient,
        fuel: this.claim.fuel,
        value: this.claim.value,
        liters: this.claim.liters,
        pump: this.claim.pump,
        fuel_second: this.claim.fuel_second,
        value_second: this.claim.value_second,
        litres_second: this.claim.litres_second,
        pump_second: this.claim.pump_second,
        ignore_second: false,
        reason: this.reason
      }
      ClaimsService.editFuel(this.claim.id, data)
      .then(() => {
          this.$success(this.$t('claims.processing.nmop.fuel_change_success'));
          this.savingFuel = false;
          this.editFuelModal.hide();
      })
      .catch(error => {
          this.$error(this.$t('claims.processing.nmop.fuel_change_error'), error);
          this.saveFuelSecond = false;
          this.editSecondFuelModal.hide();
      })
    },
    saveFuelSecond() {
      this.savingFuelSecond = true;
      let data = {
          client_id: this.$store.state.auth.user.selectedClient,
          fuel: this.claim.fuel,
          value: this.claim.value,
          litres: this.claim.litres,
          pump: this.claim.pump,
          fuel_second: this.claim.fuel_second,
          value_second: this.claim.value_second,
          litres_second: this.claim.litres_second,
          pump_second: this.claim.pump_second,
          ignore_second: false,
          reason: this.reason
      }
      ClaimsService.editFuel(this.claim.id, data)
      .then(response => {
          this.$success(this.$t('claims.processing.nmop.fuel_change_success'));
          this.savingFuelSecond = false;
          this.editSecondFuelModal.hide();
      })
      .catch(error => {
          this.$error(this.$t('claims.processing.nmop.fuel_change_error'), error);
          this.saveFuelSecond = false;
          this.editSecondFuelModal.hide();
      })
    },
    resetForm() {
      document.getElementById("FuelForm").reset();
      document.getElementById("editSecondFuelModal").hide();
    }

  },
  computed: {
    currentFee: function(){
        /**
         * 1. Check if claim is locked or not 
         * 2. Check if letter has been posted to the driver and has not been locked to lower claim 
         * 3. Check if it's been more than 2 weeks since the letter has been posted
         * 4. If true, add 30 more to fee  
         */
        let cost = 30;
        if(this.claim.lock_fee !== 1){
            if(this.claim.dates.date_posted_driver !== null && this.claim.lock_lower_fee != 1){
            if(DateTime.local().minus({'days': 17}) > DateTime.fromSeconds(this.claim.dates.date_posted_driver)){
                cost += 30;
            }
            }
        }else{
            cost = parseFloat(this.claim.locked_fee);
        }

        if(this.claim.status === "Paid on Site"){
            cost = 0;
        }

        return parseFloat(cost);
    },
    vatCharge: function(){
        return (parseFloat(this.currentFee) - (parseFloat(this.currentFee) / 1.2)).toFixed(2);
    },
    feeMinusVat: function(){
            return (parseFloat(this.currentFee) - parseFloat(this.vatCharge)).toFixed(2);
        },
    over: function(){
      if(this.claim.lock_fee !== 1){
        let today = DateTime.local();
        if(this.claim.dates.date_posted_driver !== null && this.claim.lock_lower_fee != 1){
          if(DateTime.local().minus({'days': 17}) > DateTime.fromSeconds(this.claim.dates.date_posted_driver)){
            return true
          }
        }
      }
      return false;
    },
    amountToCollect: function(){
      let amount = parseFloat(this.currentFee) + parseFloat(this.claim.value);

      if(this.claim.fuel_second !== null){
        amount += parseFloat(this.claim.value_second);
      }

      return parseFloat(amount)
    },
    withDeductions: function(){
      let amount = parseFloat(this.amountToCollect);
      amount -= parseFloat(this.paymentsMade)

      return parseFloat(amount);
    },
    paymentsMade: function(){
      let value = 0;

      this.claim.payments.forEach((payment) => {
        value += parseFloat(payment.amount);
      });

      if(this.claim.status === "Paid on Site"){
        value = this.amountToCollect;
      }
      
      return parseFloat(value);
    },
    dueDate: function(){
      if(this.over){
        return DateTime.fromSeconds(this.claim.dates.date_second_driver || 0).plus({'days': 17}).toSeconds();
      }else{
        return DateTime.fromSeconds(this.claim.dates.date_posted_driver || 0).plus({'days': 17}).toSeconds();
      }
    },
    overDue: function(){
      return DateTime.local().toSeconds() > this.dueDate;
    },
    nearlyDue: function(){
      return DateTime.local().toSeconds() < this.dueDate && DateTime.local().toSeconds() > DateTime.fromSeconds(this.dueDate).minus({'days': 4}).toSeconds();
    }
  }
};
</script>

<style scoped>

.col-6 {
  width: 48%;
}

.card.invoice {
  max-width: 700px;
}

.invoice-header {
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  background-color: #6939CE;
}

.col-9 p {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
}

thead {
  /* background-color: var(--stroke-color-primary); */
}

td {
  /* background-color: var(--bg-color-secondary); */
  border-color: transparent; 
  border-style: none;
  border-width: 0;
}

.table-invoice td {
  text-align: left;
}

th.desc {
  width: 40%;
}

th.qty {
  width: 30%;
}

.invoice-table th:not(:first-child):not(:nth-child(2)), .invoice-table td:not(:first-child):not(:nth-child(2)) {
  text-align: left;
}

.new-fuel-btn {
  /* background-color: var(--bg-color-tertiary); */
  padding: 0 50px;
}

.new-fuel-btn:hover {
  /* background-color: rgba(24, 24, 33, .6); */
  cursor: pointer;
}

.total {
  padding: 10px;
  background-color: var(--utility-red);
  border-radius: 5px;
}

.total p {
  margin: 0px;
  font-weight: 500;
}

.form-control, .form-select {
  /* background-color: #232330; */
}

.form-control::placeholder {
  /* color: rgb(74, 78, 83); */
}

.material-icons {
  cursor: pointer;
}


</style>