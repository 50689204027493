<template>
    <img :id="id" v-if="src !== null" :src="src" @click="$emit('click')" @load="$emit('load')">
</template>

<script>
import axios from 'axios';
import authHeader from '../../../../services/auth-header';
export default {
    name: 'BrandImage',
    props: {
        filename: String,
        id: String,
        thumb: {
            default: true,
            required: false,
            type: Boolean
        }
    },
    data(){
        return {
            src: null,
        }
    },
    mounted() {
        return axios.get(`${process.env.VUE_APP_API_URL}brands/${this.filename}`, {
            responseType: 'arraybuffer',
            headers: authHeader()
        })
        .then(response => {
            console.log('brandImage src: ', response);
            this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
        })
        .catch(error => {
            return null;
        });

    },
    methods: {
          loadImage(){
              return axios.get(`${process.env.VUE_APP_API_URL}brands/${this.filename}`, {
                  responseType: 'arraybuffer',
                    headers: authHeader()
              })
                  .then(response => {
                      this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                  })
                  .catch(error => {
                      return null;
                  });
          }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        id: function(val){
          this.loadImage();
        }
    }
}
</script>

<style scoped>

</style>